// antd 首先加载
@import '~antd/dist/antd.css';
// 兼容样式随后加载
// @import '~@ant-design/compatible/assets/index.css';
// radish 最后加载
@import '~radish/build/radish-csx-antd.css';

// 手动解决版本兼容问题
// 表格分页居中
.ant-table-pagination.ant-pagination {
  margin: 16px auto;
}
.ra-table-highlight-row {
  background-color: #fafafa;
  outline-offset: -2px;

  td {
    background-color: transparent !important;
  }
}

.ra-modal .ant-modal-header {
  box-shadow: 0px 0px 5px #ccc;
  position: relative;
  z-index: 1;
}

// .ra-modal-fullscreen .ant-modal .ant-modal-header ~ .ant-modal-body {
//   box-shadow: inset 0 0 5px #ccc;
// }
