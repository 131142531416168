.container {
  // background: #ffffff;
  text-align: center;
  padding: 100px;
}
.icon {
  font-size: 50px;
  color: 'red';
}
.text{
  margin-top: 50px;
}
